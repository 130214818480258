import React from 'react';
import Layout from "../components/layout/layout";
import Helmet from "react-helmet";


export default ({location}) => (
  <Layout location={location}>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className="bg-washed-red pv5 flex flex-column items-center">
      <h1 className="db f1 display fw1">Contacto</h1>
    </div>
    <div className="lh-copy f4 serif mw8 center">
      <p>Para cualquier duda no dude en contactarnos a: webmaster@labial10.com</p>
    </div>
  </Layout>
)
